declare global {
	interface Window {
		gtag: (
			option: string,
			gaTrackingId: string,
			options: Record<string, unknown>,
		) => void
	}
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = (url: string, trackingId: string) => {
	if (!window.gtag) {
		console.warn(
			'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
		)
		return
	}
	window.gtag('config', trackingId, {
		page_path: url,
	})
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({
	action,
	props,
}: {
	action: string
	props: { [key: string]: string | boolean | number }
}) => {
	if (!window.gtag) {
		console.warn(
			'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
		)
		return
	}
	window.gtag('event', action, props)
}

// https://developers.google.com/tag-platform/gtagjs/reference#set
// TODO: add logic for specific campaigns we want set on the user for all subsequent events
// we can also use the join_group event
// https://developers.google.com/tag-platform/gtagjs/reference/events#join_group
export const set = ({
	key,
	value,
}: {
	key: string
	value: { [key: string]: string | boolean | number }
}) => {
	if (!window.gtag) {
		console.warn(
			'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
		)
		return
	}
	window.gtag('set', key, value)
}
