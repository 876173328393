import { useCallback } from 'react'
import * as gtag from '#app/utils/analytics/gtags.client'
import { prepGAProperties } from '../misc'
import useAnalyticsContext from './useAnalyticsContext'

export enum USERTYPE {
	WhiteWalls = 'WhiteWalls',
}

const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID

const useAnalytics = () => {
	const { analytics } = useAnalyticsContext()

	const pageViewed = useCallback(
		(name: string) => {
			analytics.page(name)
			gtag.pageview(name, gaTrackingId)
		},
		// [gaTrackingId, analytics]
		[analytics],
	)

	const getAnonymousId = useCallback(async () => {
		const user = await analytics.user()
		const anonymousId = user.anonymousId()
		return anonymousId
	}, [analytics])

	const trackEvent = useCallback(
		(event: string, props: any = {}) => {
			analytics.track(event, props).then(d => {
				console.log('TRACK CALL SET', d)
			})
			gtag.event({
				action: event,
				props: prepGAProperties(props),
			})
		},
		[analytics],
	)

	const identifyUser = useCallback(
		(id: string, name: string, email?: string, isNewUser?: boolean) => {
			analytics
				.identify(id, {
					name,
					...(email && { email }),
				})
				.then(d => {
					console.log('IDENTIFY CALL SET', { d })
				})

			if (isNewUser) {
				gtag.event({
					action: 'sign_up',
					props: prepGAProperties({
						name,
						email,
					}),
				})
			} else {
				gtag.event({
					action: 'login',
					props: prepGAProperties({
						name,
						email,
					}),
				})
			}
		},
		[analytics],
	)

	const groupUser = useCallback(
		(userType: USERTYPE) => {
			analytics.group(`${userType} Group ID`, { userType })
			console.log('GROUP CALL SET')
		},
		[analytics],
	)

	return {
		pageViewed,
		trackEvent,
		getAnonymousId,
		identifyUser,
		groupUser,
	}
}

export default useAnalytics
